import { format } from 'date-fns'
import { configApi } from '../http-client.js'
import { DATE_FORMAT } from '../date-formatting.js'

// export const getPreferencePages = () => configApi.get('/api/PreferencePages')
export const getPreferencePages = () => configApi.get('/api/PreferencePages').then(({ data }) => ({
	preferencePages: data.preferencePages.map(preferencePage => ({
		...preferencePage,
		createdDate: format(new Date(preferencePage.createdDate), DATE_FORMAT),
		complete: preferencePage.status === 5
	}))
}))

export const getPreferencePage = id => configApi.get(`/api/PreferencePages/${id}`).then(({ data }) => ({
	preferencePage: {
		...data.preferencePage,
		name: data.preferencePage.pageName,
		description: data.preferencePage.pageDescription,
		configurationOptions: {
			...data.preferencePage.configurationOptions,
			ipLimitations: {
				...data.preferencePage.configurationOptions.ipLimitations,
				limitSubmissionsByIpTypeId: data.preferencePage.configurationOptions.ipLimitations.limitSubmissionsByIpTypeId === 2
			}
		}
	}
}))

export const createPreferencePage = preferencePage => configApi.post('/api/PreferencePages', preferencePage)

export const updatePreferencePage = (id, preferencePage) => configApi.put(`/api/PreferencePages/${id}`, preferencePage)

export const deletePreferencePage = id => {
	const PREFERENCE_PAGE_TYPE_ID = 3
	return configApi.delete(`/api/Links?linkId=${id}&linkTypeId=${PREFERENCE_PAGE_TYPE_ID}`)
}

// Translations

export const getPreferencePageTranslation = (id, languageId) => configApi.get(`/api/PreferencePages/Translations/${id}/${languageId}`).then(({ data }) => ({
	preferencePageTranslation: {
		...data
	}
}))

export const putPreferencePageTranslation = (id, translation) => configApi.put(`/api/PreferencePages/Translations/${id}`, translation)

export const deletePreferencePageTranslation = (id, languageId) => configApi.delete(`/api/PreferencePages/Translations/${id}/${languageId}`)
export const getPreferencePageTranslations = languageId => configApi.get(`/api/PreferencePages/Translations?languageId=${languageId}`)
