import { configApi, setBearerToken } from '../../../../shared/utils/http-client.js'
import { ssoLoginApi } from '../../../../shared/utils/api/login.js'
import { COOKIE_MODULE_AREA_FULL_PERMISSIONS, COMPONENTS_MODULE_FULL_PERMISSIONS } from '../../../../shared/permissions/admin-portal-permissions.js'
import router from '../../router/index.js'
import { changeUserBrandIds, resetBrands } from '../../../../shared/state/brands.js'
import { parseToken } from '../../../../shared/utils/parse-token.js'
import { SSO_ERROR, SESSION_EXPIRED } from '../../router/route-names.js'
import { clearNavbarItems } from '../../../../shared/state/admin-portal-navigation.js'

const COOKIE_MODULE_AREA_PERMISSIONIDS = [1, 2, 3, 4, 5, 6, 7, 8, 9]
const COMPONENTS_AREA_PERMISSIONIDS = [52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 70]

export const state = {
	currentUser: getSavedState('auth.currentUser'),
	userDetails: {},
	migrationUrl: null,
	isSSO: false,
	sessionWarning: false,
	countdown: 0
}

const getCookieValue = name =>
	document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''

let sessionCheckInterval

export const mutations = {
	SET_CURRENT_USER (state, newValue) {
		state.currentUser = newValue
		saveState('auth.currentUser', newValue)
		if (newValue == null) {
			state.userDetails = {}
		}
	},
	SET_USER_DETAILS (state, userDetails) {
		state.userDetails = userDetails
		changeUserBrandIds(userDetails.brandIds)
	},
	SET_MIGRATION_URL (state, migrationUrl) {
		state.migrationUrl = migrationUrl
	},
	SET_SESSION_WARNING (state, warning) {
		state.sessionWarning = warning
	},
	SET_COUNTDOWN (state, countdown) {
		state.countdown = countdown
	}
}

export const getters = {
	// Whether the user is currently logged in.
	loggedIn (state) {
		return !!state.currentUser?.userId
	},
	userPermissionsRetrieved (state) {
		return !!state.userDetails?.productPermissions
	},
	userFullName (state) {
		return state.userDetails?.fullName
	},
	userId (state) {
		return state.currentUser?.userId
	},
	userDefaultDashboardId (state) {
		return state.userDetails?.defaultDashboardId
	},
	migrationUrl (state) {
		return state.migrationUrl
	},
	productAreaPermission: state => permissionId =>
		checkUserHasPermission(state, permissionId),
	tokenInDate (state) {
		if (!state.currentUser?.userId) {
			return false
		}
		const expiry = new Date(0)
		expiry.setUTCSeconds(state.currentUser.tokenExpiry)
		const now = new Date()
		return (expiry > now)
	},
	userHasUniversalBrand (state) {
		return state.userDetails?.brandIds.includes(0)
	},
	userBrands (state) {
		const brands = []
		brands.push(...state.userDetails?.brandIds)
		return brands
	},
	sessionCookieExists () {
		const token = getCookieValue('admin-portal-token')
		return !!token
	},
	loggedInWithSSO (state) {
		return state.currentUser?.isSSO
	},
	sessionWarning (state) {
		return state.sessionWarning
	},
	countdown (state) {
		return state.countdown
	}
}

export const actions = {
	setJwtTokenFromString ({ commit, dispatch }, jwtString) {
		if (jwtString) {
			const parsedToken = parseToken(jwtString)
			const jwtTokenExpiry = parsedToken.exp
			const jwtUserId = parsedToken.userId
			const user = {
				tokenExpiry: jwtTokenExpiry,
				userId: jwtUserId
			}
			setBearerToken(jwtString)
			setTokenCookie(jwtString)
			commit('SET_CURRENT_USER', user)
			dispatch('startSessionCheck')
			return Promise.resolve(null)
		}
	},
	checkForExistingSession ({ commit, getters, dispatch }) {
		if (getters.sessionCookieExists) {
			const token = getCookieValue('admin-portal-token')
			if (token != null && token !== '') {
				setBearerToken(token)
				const parsedToken = parseToken(token)
				const user = {
					userId: getters.userId,
					tokenExpiry: parsedToken.exp
				}
				commit('SET_CURRENT_USER', user)
				dispatch('startSessionCheck')
			}
		}
	},
	ssoLogIn ({ dispatch }, { identityClaimTypeName, stateGuid, productId }) {
		return ssoLoginApi({ identityClaimTypeName, stateGuid, productId }).then(({ data }) => dispatch('handleSSOLogin', { data: data, isSSO: true }).then)
	},
	// Logs in the current user.
	logIn ({ commit, dispatch, getters }, { username, password } = {}) {
		if (getters.loggedIn) return dispatch('validate')
		return configApi
			.post('/api/authorize/adminportaluser', { emailAddress: username, password })
			.then(response => {
				// 202 response code indicates OTP login is required
				if (response.status === 202) {
					localStorage.setItem('mfaGuid', response.data.mfaGuid)
					return {
						otpLoginRequired: true
					}
				} else {
					const parsedToken = parseToken(response.data.token)
					const user = {
						tokenExpiry: parsedToken.exp,
						userId: parsedToken.userId,
						otpLoginRequired: false
					}
					commit('SET_CURRENT_USER', user)
					setBearerToken(response.data.token)
					setTokenCookie(response.data.token)
					dispatch('startSessionCheck')
					return user
				}
			})
	},
	otpLogIn ({ commit, dispatch, getters }, { otpCode, mfaGuid } = {}) {
		if (getters.loggedIn) return dispatch('validate')
		clearNavbarItems()
		return configApi
			.post('/api/Authorize/LoginOtp', { loginCode: otpCode, mfaGuid })
			.then(response => {
				const parsedToken = parseToken(response.data.token)
				const user = {
					tokenExpiry: parsedToken.exp,
					userId: parsedToken.userId,
					oneTimePass: response.oneTimePass
				}
				commit('SET_CURRENT_USER', user)
				setBearerToken(response.data.token)
				setTokenCookie(response.data.token)
				dispatch('startSessionCheck')
				return user
			})
	},
	// SSO login
	async handleSSOLogin ({ commit, dispatch, getters }, { data }) {
		if (getters.loggedIn) return dispatch('validate')
		setBearerToken(data.token)
		setTokenCookie(data.token)
		const parsedToken = parseToken(data.token)
		const user = {
			tokenExpiry: parsedToken.exp,
			userId: parsedToken.userId,
			isSSO: true
		}
		commit('SET_CURRENT_USER', user)
		dispatch('startSessionCheck')
		await dispatch('getUserDetails')
	},
	// Get sensitive user details.
	getUserDetails ({ commit, dispatch, getters } = {}) {
		if (getters.loggedIn && getters.tokenInDate) {
			return configApi
				.get('/api/adminportalusers/getcurrentuserdetails')
				.then(({ data }) => {
					const userDetails = {
						fullName: data.currentUserDetails.userPreview.fullName,
						defaultPage: data.currentUserDetails.userPreview.defaultPage,
						brandIds: data.currentUserDetails.brandIds,
						defaultDashboardId: data.currentUserDetails.user.defaultDashboardId,
						productPermissions: data.currentUserDetails.permissionIds
					}
					commit('SET_USER_DETAILS', userDetails)
					resetBrands()
					return userDetails
				})
				.catch(error => {
					console.warn(error)
					commit('SET_CURRENT_USER', null)
					dispatch('logOut', SESSION_EXPIRED)
					return Promise.resolve(null)
				})
		} else {
			commit('SET_CURRENT_USER', null)
			dispatch('logOut', SESSION_EXPIRED)
			return Promise.resolve(null)
		}
	},
	getLegacyUrl ({ commit, getters } = {}) {
		if (getters.migrationUrl != null) {
			return getters.migrationUrl
		}
		return configApi
			.get('/api/LegacyUrls')
			.then(({ data }) => {
				const migrationUrl = []
				migrationUrl.push(data.useVueAdminPortalLoginPage)
				migrationUrl.push(data.legacyAdminPortalUrl)
				commit('SET_MIGRATION_URL', migrationUrl)
				return migrationUrl
			})
			.catch(error => {
				console.error(error)
				return Promise.resolve(null)
			})
	},
	// Logs out the current user.
	logOut ({ getters, dispatch }, routeName) {
		clearInterval(sessionCheckInterval)
		if (routeName === SESSION_EXPIRED) {
			dispatch('handleLogout', routeName)
			return
		}

		if (getters.loggedIn && getters.tokenInDate) {
			return configApi.post('/api/authorize/adminportaluserlogout', { revokeAllSessions: false })
				.finally(() => {
					dispatch('handleLogout', routeName)
				})
		} else {
			dispatch('handleLogout', routeName)
		}
	},
	handleLogout ({ getters, commit }, routeName) {
		commit('SET_CURRENT_USER', null)
		commit('SET_SESSION_WARNING', false)
		// Clear down cookies
		removeCookie('admin-portal-token')
		removeCookie('ASP.NET_SessionId')
		// clear builder storage
		localStorage.removeItem('vueform-builder')
		localStorage.removeItem('vueform-dark-mode')
		localStorage.removeItem('vueform-element-closed')
		localStorage.removeItem('vueform-history')
		localStorage.removeItem('vueform-themes')
		clearNavbarItems()

		// If the current route is Session Expired, do not proceed with redirects or further API calls
		if (routeName === SESSION_EXPIRED) {
			return
		}

		const migrationUrl = getters.migrationUrl
		const tenantGuid = sessionStorage.getItem('tenantGuid')
		const authHandler = sessionStorage.getItem('authHandler')
		const migjwt = sessionStorage.getItem('migjwt')
		const isSSO = sessionStorage.getItem('isSSO')

		if (isSSO != null && tenantGuid != null && authHandler != null) {
			sessionStorage.removeItem('tenantGuid')
			sessionStorage.removeItem('authHandler')
			sessionStorage.removeItem('isSSO')
			if (routeName === SSO_ERROR) {
				router.push({ name: SSO_ERROR })
				return
			}
			window.location.href = `${authHandler}/signOut/${tenantGuid}`
		} else if (migrationUrl != null && migjwt === 'true') {
			sessionStorage.removeItem('migjwt')
			location.href = 'https://' + migrationUrl[1] + '/Home/Logout'
		} else {
			router.push({ path: '/login' })
		}
	},
	// Validates the current user's token and refreshes it
	// with new data from the API.
	validate ({ commit, dispatch, state, getters }) {
		if (!state.currentUser) return Promise.resolve(null)
		const expiry = new Date(0)
		expiry.setUTCSeconds(state.currentUser.tokenExpiry)
		const now = new Date()
		const closeToExpire = new Date(expiry - 1500000)
		const tokenInDateAndNotAboutToExpire = (now < closeToExpire)
		if (getters.loggedIn && tokenInDateAndNotAboutToExpire) {
			return true
		}
		// Read cookie and ensure currentUser is populated and bearer token is set
		const token = getCookieValue('admin-portal-token')
		if (!token) {
			commit('SET_CURRENT_USER', null)
			dispatch('logOut', SESSION_EXPIRED)
			return Promise.resolve(null)
		}
		setBearerToken(token)
		const parsedToken = parseToken(token)
		const user = {
			userId: getters.userId,
			tokenExpiry: parsedToken.exp
		}
		commit('SET_CURRENT_USER', user)
		// Check token expiry date
		expiry.setUTCSeconds(state.currentUser.tokenExpiry)
		if (now > expiry) {
			commit('SET_CURRENT_USER', null)
			dispatch('logOut', SESSION_EXPIRED)
			return Promise.resolve(null)
		}
		// Only call refresh token when there are 5 minutes or fewer before expiry
		return configApi
			.get('/api/authorize/adminportaluserrefreshtoken')
			.then(response => {
				const newParsedToken = parseToken(response.data.token)
				const tokenViewModel = { token: response.data.token, tokenExpiry: newParsedToken.exp }
				const newUser = {
					userId: getters.userId,
					tokenExpiry: newParsedToken.exp
				}
				setBearerToken(response.data.token)
				setTokenCookie(response.data.token)
				commit('SET_CURRENT_USER', newUser)
				commit('SET_SESSION_WARNING', false)
				dispatch('startSessionCheck')
				return tokenViewModel
			})
			.catch(error => {
				if (error.response && error.response.status === 401) {
					commit('SET_CURRENT_USER', null)
				} else {
					console.warn(error)
				}
				return Promise.resolve(null)
			})
	},
	startSessionCheck ({ state, commit, dispatch }) {
		clearInterval(sessionCheckInterval)
		sessionCheckInterval = setInterval(() => {
			const expiry = new Date(0)
			expiry.setUTCSeconds(state.currentUser.tokenExpiry)
			const now = new Date()
			const timeLeft = expiry - now

			if (timeLeft <= 0) {
				dispatch('sessionExpired') // Dispatch action to handle session expiration
			} else if (timeLeft <= 60000) { // Show 1 minute before session expires
				commit('SET_SESSION_WARNING', true)
				commit('SET_COUNTDOWN', Math.floor(timeLeft / 1000)) // Set initial countdown value

				// Start countdown
				const countdownInterval = setInterval(() => {
					const newTimeLeft = expiry - new Date()
					if (newTimeLeft <= 0 || !state.sessionWarning) {
						clearInterval(countdownInterval)
						if (newTimeLeft <= 0) {
							dispatch('sessionExpired')
						}
					} else {
						commit('SET_COUNTDOWN', Math.floor(newTimeLeft / 1000))
					}
				}, 1000)
			} else {
				commit('SET_SESSION_WARNING', false)
			}
		}, 60000) // Check every 60 seconds
	},
	sessionExpired ({ commit, dispatch }) {
		commit('SET_CURRENT_USER', null)
		commit('SET_SESSION_WARNING', false)
		dispatch('handleLogout', SESSION_EXPIRED)

		router.push({ name: SESSION_EXPIRED })
	}
}

// ===
// Private helpers
// ===

function getSavedState (key) {
	return JSON.parse(window.localStorage.getItem(key))
}

function saveState (key, state) {
	window.localStorage.setItem(key, JSON.stringify(state))
}

function setTokenCookie (token) {
	if (!token) {
		removeCookie('admin-portal-token')
	} else {
		const parsedToken = parseToken(token)
		const expiry = new Date(0)
		expiry.setUTCSeconds(parsedToken.exp)
		document.cookie =
    'admin-portal-token=' + token + '; expires=' + expiry.toUTCString() + '; path=/;'
	}
}

function checkUserHasPermission (state, permissionId) {
	if (state.userDetails?.productPermissions?.includes(COOKIE_MODULE_AREA_FULL_PERMISSIONS) && (COOKIE_MODULE_AREA_PERMISSIONIDS.includes(permissionId))) return true
	if (state.userDetails?.productPermissions?.includes(COMPONENTS_MODULE_FULL_PERMISSIONS) && (COMPONENTS_AREA_PERMISSIONIDS.includes(permissionId))) return true
	return state.userDetails?.productPermissions?.includes(
		permissionId
	)
}

function removeCookie (cookieName) {
	if (cookieName) {
		document.cookie = cookieName.trim() + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
	}
}
